import React, { useState, useEffect, useContext } from "react"
import { Grid, Typography, Button, TextField, Box } from "@mui/material"
import api from "../../apiConfig"
import Cookies from "js-cookie"
import { AuthContext } from "../../context/AuthContext"
import WaitingTimeComponent from "./WaitingTimeComponent"
import { CircularProgress } from "@mui/material"

const CurrentJob = ({ job, currentTime }) => {
  const [isArrived, setIsArrived] = useState(false)
  const [isWaiting, setIsWaiting] = useState(false)
  const [waitingTime, setWaitingTime] = useState(0)
  const [waitingStartTime, setWaitingStartTime] = useState(null)
  const [startMeterReading, setStartMeterReading] = useState("")
  const [isTripStarted, setIsTripStarted] = useState(false)
  const [endMeterReading, setEndMeterReading] = useState("")
  const [isTripEnded, setIsTripEnded] = useState(false)
  const [tripEndTime, setTripEndTime] = useState(null)
  const [tripDetails, setTripDetails] = useState([])
  const [tripInit, setTripInit] = useState()




  const [isLoading, setIsLoading] = useState(false)

  const { username } = useContext(AuthContext)

  useEffect(() => {
    if (!job) return

    const {
      id,
      status,
      waitingStartTime,
      waitingTime,
      startMeterReading,
      endMeterReading,
      tripEndTime,
      pickupLocation,
      destination,
      pickupTime,
      clientName,
      clientPhone,
    } = job

    setTripInit({
      id,
      clientName,
      clientPhone,
      pickupLocation,
      dropLocation: destination,
      pickupTime: new Date(pickupTime),
    })

    switch (status) {
      case "WAITING_BEFORE":
        setIsArrived(true)
        setIsWaiting(true)
        setWaitingStartTime(new Date(waitingStartTime))
        setWaitingTime(Number(waitingTime))
        break
      case "STARTED":
        setIsArrived(true)
        setIsTripStarted(true)
        setStartMeterReading(Number(startMeterReading))
        setWaitingTime(Number(waitingTime))
        break
      case "WAITING_DURING":
        setIsArrived(true)
        setIsTripStarted(true)
        setIsWaiting(true)
        setWaitingStartTime(new Date(waitingStartTime))
        setWaitingTime(Number(waitingTime))
        break
      case "TRIPEND":
        setIsArrived(true)
        setIsTripStarted(true)
        setIsTripEnded(true)
        setStartMeterReading(startMeterReading)
        setEndMeterReading(Number(endMeterReading))
        setTripEndTime(new Date(tripEndTime))
        setWaitingTime(Number(waitingTime))
        break
      default:
        break
    }
  }, [job])

  useEffect(() => {
    let interval
    if (isWaiting) {
      const colomboTime = new Date(
        new Intl.DateTimeFormat("en-US", {
          timeZone: "Asia/Colombo",
        }).format(Date.now())
      )

      const startTimeInSeconds = Math.floor(
        (Date.now() - new Date(waitingStartTime)) / 1000
      )
      setWaitingTime(waitingTime + startTimeInSeconds)
      interval = setInterval(() => {
        setWaitingTime((prevTime) => prevTime + 1)
      }, 1000)
    } else if (!isWaiting && waitingStartTime) {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [isWaiting, waitingStartTime])

const handleArrivedClick = async () => {
  setIsLoading(true) // Set loading to true
  try {
    await api.post(`/api/v1/tripcost/arrived/${tripInit.id}`, {
      driverPhone: username,
    })
    setIsWaiting(true)
    setIsArrived(true)
    setIsTripStarted(false)
    setIsTripEnded(false)
    setWaitingStartTime(new Date())
  } catch (error) {
    console.error("Error marking arrival:", error)
    alert("An error occurred while marking arrival.")
  } finally {
    setIsLoading(false) // Set loading to false
  }
}

const handleStartTripClick = async () => {
  setIsLoading(true) // Set loading to true
  try {
    await api.post(`/api/v1/tripcost/start/${tripInit.id}`, {
      start_meter_reading: Number(startMeterReading),
    })
    setIsWaiting(false)
    setIsTripStarted(true)
  } catch (error) {
    console.error("Error starting trip:", error)
    alert("An error occurred while starting the trip.")
  } finally {
    setIsLoading(false) // Set loading to false
  }
}

const handleWaitingClick = async () => {
  setIsLoading(true) // Set loading to true
  try {
    if (isWaiting) {
      await api.post(`/api/v1/tripcost/endWaiting/${tripInit.id}`)
      setIsWaiting(false)
    } else {
      await api.post(`/api/v1/tripcost/startWaiting/${tripInit.id}`)
      setWaitingStartTime(new Date())
      setIsWaiting(true)
    }
  } catch (error) {
    console.error("Error toggling waiting state:", error)
    alert("An error occurred while updating waiting state.")
  } finally {
    setIsLoading(false) // Set loading to false
  }
}

  const handleEndTripClick = () => {
    if (endMeterReading > startMeterReading) {
      setIsLoading(true) // Start loading during the entire process

      api
        .post(`/api/v1/tripcost/end/${tripInit.id}`, {
          end_meter_reading: Number(endMeterReading),
        })
        .then((response) => {
          console.log("Trip ended successfully:", response.data)

          // Now trigger the second request to get trip details
          return api.get(`/api/v1/trip/${tripInit.id}`)
        })
        .then((response) => {
          if (response.data.status === 200) {
            const data = response.data.data
            const details = [
              {
                label: "Waiting Time",
                value: data.waitingTimeSum
                  ? new Date(data.waitingTimeSum * 1000)
                      .toISOString()
                      .substr(11, 8)
                  : "00:00:00",
              },
              { label: "Cost is", value: data.finalCost || "1000" },
              {
                label: "Company Charge is",
                value: data.companyCharge || "1000",
              },
            ]
            setTripDetails(details)
          }
        })
        .catch((error) => {
          console.error("Error occurred:", error)

          // Display a relevant error message
          if (error.response) {
            alert(
              `Error: ${
                error.response.data.message ||
                "An error occurred while processing your request."
              }`
            )
          } else if (error.request) {
            alert("Error: No response received from the server.")
          } else {
            alert(`Error: ${error.message}`)
          }
        })
        .finally(() => {
          setIsLoading(false) // Stop loading
          setIsTripEnded(true) // Update the state to indicate trip has ended
          setTripEndTime(new Date()) // Update the trip end time
        })
    } else {
      alert("End meter reading must be greater than start meter reading.")
    }
  }



  if (!tripInit) return <Typography variant="h6">No active trips</Typography>

  return (
    <Box sx={{ backgroundColor: "white", padding: 3, borderRadius: 2 }}>
      <Grid container spacing={2} textAlign="left">
        <Grid item xs={12}>
          <Typography>Trip ID: {tripInit.id}</Typography>
          <Typography>Client Name: {tripInit.clientName}</Typography>
          <Typography>Client Phone: {tripInit.clientPhone}</Typography>
          <Typography>Pickup Location: {tripInit.pickupLocation}</Typography>
          <Typography>Drop Location: {tripInit.dropLocation}</Typography>
          <Typography>
            Pickup Time: {tripInit.pickupTime.toLocaleString()}
          </Typography>

          {isTripEnded && (
            <Box mt={2}>
              {isLoading ? (
                <Box display="flex" alignItems="center">
                  <CircularProgress size={24} />
                  <Typography ml={2}>Calculating cost...</Typography>
                </Box>
              ) : (
                tripDetails.map((detail) => (
                  <Typography key={detail.label}>
                    {detail.label}: {detail.value}
                  </Typography>
                ))
              )}
            </Box>
          )}

          {!isArrived && (

            <Button
              variant="contained"
              sx={{
                backgroundColor: "#FFCF43",
                "&:hover": {
                  backgroundColor: "#FFD8A3", // Light yellow color on hover
                },
              }}
              onClick={handleArrivedClick}
              // disable the button if the current time and the pickup time difference is grater than 5 minutes
              // disabled={
              //   isLoading||parseInt((tripInit.pickupTime - currentTime) / 1000) > 300
              // }
              disabled={(() => {
                const diff = parseInt((tripInit.pickupTime - currentTime) / 1000);
                // console.log("pickupTime:", tripInit.pickupTime);
                // console.log("currentTime:", currentTime);
                // console.log("Time Difference (seconds):", diff);
                // console.log("Disabled:", isLoading || diff > 300);
                return isLoading || diff > 300;
              })()}
              // disabled={() => {
              //   const diff = parseInt((tripInit.pickupTime - currentTime) / 1000);
              //   console.log("pickupTime:", tripInit.pickupTime);
              //   console.log("currentTime:", currentTime);
              //   console.log("Time Difference (seconds):", diff);
              //   console.log("Disabled:", isLoading || diff > 300);
              //   return isLoading || diff > 300;
              // }}
            >
              Arrived
            </Button>
          )}

          {isArrived && !isTripStarted && (
            <Box mt={2}>
              <WaitingTimeComponent
                waitingTimeSum={waitingTime}
                waitingStartTime={waitingStartTime}
                isWaiting={isWaiting}
                setWaitingTimeSum={setWaitingTime}
              />
              <TextField
                inputProps={{
                  inputMode: "numeric",
                  pattern: "/^-?d+(?:.d+)?$/g",
                }}
                label="Start Meter Reading"
                value={startMeterReading}
                onChange={(e) => setStartMeterReading(e.target.value)}
                variant="outlined"
                fullWidth
              />
              <Button
                variant="contained"
                csx={{
                  backgroundColor: "#FFCF43",
                  "&:hover": {
                    backgroundColor: "#FFD8A3", // Light yellow color on hover
                  },
                }}
                onClick={handleStartTripClick}
                disabled={isLoading||!startMeterReading}
              >
                Save and Start Trip
              </Button>
            </Box>
          )}

          {isTripStarted && !isTripEnded && !isLoading && (
            <Box mt={2}>
              <WaitingTimeComponent
                waitingTimeSum={waitingTime}
                waitingStartTime={waitingStartTime}
                isWaiting={isWaiting}
                setWaitingTimeSum={setWaitingTime}
              />
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#FFCF43",
                  "&:hover": {
                    backgroundColor: "#FFD8A3", // Light yellow color on hover
                  },
                }}
                onClick={handleWaitingClick}
              >
                {isWaiting ? "End Waiting" : "Start Waiting"}
              </Button>
              {!isWaiting  && (
                <TextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "/^-?d+(?:.d+)?$/g",
                  }}
                  label="End Meter Reading"
                  value={endMeterReading}
                  onChange={(e) => setEndMeterReading(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
              )}
            </Box>
          )}

          {isTripStarted && !isWaiting && !isTripEnded && !isLoading && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#FFCF43",
                "&:hover": {
                  backgroundColor: "#FFD8A3", // Light yellow color on hover
                },
              }}
              onClick={handleEndTripClick}
              disabled={Number(endMeterReading) <= Number(startMeterReading)}
            >
              End Trip
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default CurrentJob
