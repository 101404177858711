import React, { useContext, useEffect } from "react"
import EnhancedTable from "./TripTable"
import api from "../../../apiConfig"
import { NavigationContext } from "../../../context/NavigationContext"
import { TripContext } from "../../../context/TripContext"
import dayjs from "dayjs"

const formatDate = (date) => {
  return date.toISOString().split("T")[0] // Converts to "YYYY-MM-DD"
}

const toDateTimeObject = (dateStr, timeStr = "00:00:00") => {
  return new Date(`${dateStr}T${timeStr}`)
}

const Trip = () => {
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(today.getDate() + 2)

  const [startDate, setStartDate] = React.useState(
    // formatDate(new Date("2020-01-01T00:00:00"))
    formatDate(today)
  )
  const [endDate, setEndDate] = React.useState(formatDate(tomorrow)) // Current date
  const [status, setStatus] = React.useState("ALL")
  const [count, setCount] = React.useState(0)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(100)
  const { setRowData } = useContext(TripContext)

  useEffect(() => {
    api
      .get("/api/v1/trip/list", {
        params: {
          searchText: "",
          page: page,
          size: rowsPerPage,
          startDate: dayjs(toDateTimeObject(startDate))
            .tz("Asia/Colombo")
            .format("YYYY-MM-DDTHH:mm:ss"),
          endDate: dayjs(toDateTimeObject(endDate, "23:59:59"))
            .tz("Asia/Colombo")
            .format("YYYY-MM-DDTHH:mm:ss"),
          tripStatus: status,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          console.log("Trips fetched!", response.data)
          setCount(response.data.data.count)
          setRowData(
            response.data.data.dataList.map((trip) => ({
              id: trip.propertyId,
              published: trip.publishedDate,
              pickup_location: trip.pickupLocation,
              driverId: trip.driverId,
              driver: trip.driverId
                ? `${trip.driverName} (${trip.driverPhone})`
                : "Not Assigned",
              pickup_time: trip.pickupTime,
              destination: trip.destination,
              status: trip.status,
              company_charge: trip.companyCharge,
              end_meter_reading: trip.endMeterReading,
              start_meter_reading: trip.startMeterReading,
              estimate_cost: trip.estimateCost,
                remarks:trip.remarks,
              final_cost: trip.finalCost,
              source: trip.source,
              trip_end_time: trip.tripEndTime,
              trip_start_time: trip.tripStartTime,
              waiting_start_time: trip.waitingStartTime,
              waiting_time_sum: trip.waitingTimeSum || 0,
              client_name: trip.clientName,
              client_phone: trip.clientPhone,
              client_email: trip.clientEmail,
            }))
          )
        } else {
          console.log("Something went wrong!", response.data.data.dataList)
        }
      })
  }, [rowsPerPage, setRowData, page, startDate, endDate, status])

  return (
    <EnhancedTable
      count={count}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      startDate={startDate}
      endDate={endDate}
      setStatus={setStatus}
      status={status}
    />
  )
}

export default Trip
