import React, { useState } from "react"
import { Stack, Box, Button, Typography } from "@mui/material"
import { motion } from "framer-motion"
import EstimateForm from "./EstimateForm"
import ReservationForm from "./ReservationForm"
import MobileForm from "./MobileForm"
import api from "../../../../apiConfig"
import YellowButton from "../../../../components/YellowButton"
import MotivateCards from "./MotivateCard"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import axios from "axios"

// Enable the plugins
dayjs.extend(utc)
dayjs.extend(timezone)

const slideVariants = {
  enter: (direction) => ({
    x: window.innerWidth >= 600 ? (direction > 0 ? "100%" : "-100%") : 0,
    y: window.innerWidth < 600 ? (direction > 0 ? "100%" : "-100%") : 0,
    opacity: 0,
  }),
  center: {
    x: 0,
    y: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    x: window.innerWidth >= 600 ? (direction < 0 ? "100%" : "-100%") : 0,
    y: window.innerWidth < 600 ? (direction < 0 ? "100%" : "-100%") : 0,
    opacity: 0,
  }),
}

const buttonVariants = {
  enter: (direction) => ({
    x: window.innerWidth >= 600 ? (direction > 0 ? "100%" : "-100%") : 0,
    y: window.innerWidth < 600 ? (direction > 0 ? "100%" : "-100%") : 0,
    opacity: 0,
  }),
  center: {
    x: 0,
    y: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    x: window.innerWidth >= 600 ? (direction < 0 ? "-100%" : "100%") : 0,
    y: window.innerWidth < 600 ? (direction < 0 ? "-100%" : "100%") : 0,
    opacity: 0,
  }),
}

const NewsBar = () => {
  const [selectedOption, setSelectedOption] = useState("estimate")
  const [pickupValue, setPickupValue] = useState("")
  const [dropValue, setDropValue] = useState("")
  const [distance, setDistance] = useState(null)
  const [estimatedCostValue, setEstimatedCostValue] = useState(null)
  const [openPopups, setOpenPopups] = useState({})
  const [formValues, setFormValues] = useState({
    pickup_time: "",
    name: "",
    email: "",
    phone: "",
    promoCode: "",
  })
  const [checkboxChecked, setCheckboxChecked] = useState(false)
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    pickup: false,
    pickup_time: false,
    drop: false,
    distance: false,
    checkbox: false,
  })
  const [direction, setDirection] = useState(-1) // To control animation direction
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const handleClosePopup = (page) => {
    setOpenPopups({ ...openPopups, [page]: false })
  }

  const handleOpenPopup = (page) => {
    setOpenPopups({ ...openPopups, [page]: true })
  }

  const handleButtonClick = (option) => {
    document.getElementById("news-bar").scrollIntoView({ behavior: "smooth" })
    // setDirection(option === "estimate" ? -1 : 1)
    setSelectedOption(option)
  }

  const calculateDistance = async ({ origin, destination }) => {
    if (window.google && window.google.maps) {
      return new Promise((resolve, reject) => {
        const service = new window.google.maps.DistanceMatrixService()
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode: window.google.maps.TravelMode.WALKING,
          },
          (response, status) => {
            if (status === window.google.maps.DistanceMatrixStatus.OK) {
              if (
                response.destinationAddresses[0] === "" ||
                response.originAddresses[0] === ""
              ) {
                alert("Invalid address")
                reject("Invalid address")
              } else {
                const distance = response.rows[0].elements[0].distance.text
                resolve(distance)
              }
            } else {
              reject("Distance Matrix request failed")
            }
          }
        )
      })
    } else {
      throw new Error("Google Maps API not loaded")
    }
  }

  const estimateCost = async ({ origin, destination }) => {
    const calculatedDistance = await calculateDistance({
      origin: pickupValue,
      destination: dropValue,
    })
    // calculate distance is like 40.5 km change it to 40.5
    // calculatedDistance = parseFloat(calculatedDistance.split(" ")[0])
    setDistance(calculatedDistance)
    console.log(calculatedDistance)
    if (calculatedDistance) {
      let params = {
        distance: parseFloat(calculatedDistance.split(" ")[0]),
        promo: formValues.promoCode || "",
      }
      // if (!formValues.promoCode) delete params.promo
      api
        .get("/api/v1/tripcost/estimate", {
          params: params,
        })
        .then((response) => {
          if (response.data.status === 200) {
            console.log("Estimated cost:", response.data.data)
            if (
              response.data.data.promoStatus !== "OK" &&
              formValues.promoCode
            ) {
              alert("Invalid Promo Codmme")
            }

            setEstimatedCostValue(response.data.data.totalCharge)
          } else {
            alert("Error estimating cost")
            console.log("Error estimating cost:", response.data)
            setEstimatedCostValue(null)
          }
        })
    } else {
      alert("Error calculating distance")
      console.log("Error calculating distance")
      setEstimatedCostValue(null)
    }
  }

  const validateForm = async () => {
    let valid = true
    let tempErrors = { ...errors }

    // Reset errors
    tempErrors = {
      name: false,
      email: false,
      phone: false,
      pickup: false,
      drop: false,
      distance: false,
      checkbox: false,
      pickup_time: false,
    }

    // Time validation must be in the future more than 15 minutes
    const minPickupTime = new Date()
    minPickupTime.setMinutes(minPickupTime.getMinutes() + 15)
    if (!formValues.pickup_time) {
      tempErrors.pickup_time = true
      valid = false
    }

    if (new Date(formValues.pickup_time) < minPickupTime) {
      tempErrors.pickup_time = true
      valid = false
    }

    if (formValues.name.trim() === "") {
      tempErrors.name = true
      valid = false
    }

    // Email validation regex
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    // if (!emailRegex.test(formValues.email)) {
    //   tempErrors.email = true
    //   valid = false
    // }

    // Phone number validation regex (simple format check)
    const phoneRegex = /^\+?[0-9]{10}$/
    if (!phoneRegex.test(formValues.phone)) {
      tempErrors.phone = true
      valid = false
    }

    // Validate pickup and drop values
    if (pickupValue.trim() === "") {
      tempErrors.pickup = true
      valid = false
    }

    if (dropValue.trim() === "") {
      tempErrors.drop = true
      valid = false
    }

    // Validate distance calculation
    if (pickupValue && dropValue) {
      try {
        const calculatedDistance = await calculateDistance({
          origin: pickupValue,
          destination: dropValue,
        })
        setDistance(calculatedDistance)
      } catch (error) {
        console.error("Error calculating distance:", error)
        tempErrors.distance = true
        valid = false
      }
    } else {
      tempErrors.distance = true
      valid = false
    }

    // Checkbox validation
    if (!checkboxChecked) {
      tempErrors.checkbox = true
      valid = false
    }

    setErrors(tempErrors)
    return valid
  }

  const handleReservation = async () => {
    const isValid = await validateForm()

    const data = {
      pickupLocation: pickupValue,
      destination: dropValue,
      pickupTime: dayjs(formValues.pickup_time)
        .tz("Asia/Colombo")
        .format("YYYY-MM-DDTHH:mm:ss"),
      source: "WEB",
      client: {
        name: formValues.name,
        email: formValues.email,
        phone: formValues.phone,
      },
      tripStatus: "DRAFT",
      estimatedCost: estimatedCostValue || 0,
    }

    console.log("Data", data)
    if (isValid) {
      api.post("/api/v1/trip", data).then((response) => {
        if (response.data.status === 201) {
          console.log("Reservation successful!", response.data)
          alert("Reservation successful!")
          setPickupValue("")
          setDropValue("")
          setDistance(null)
          setEstimatedCostValue(null)
          setFormValues({
            pickup_time: "",
            name: "",
            email: "",
            phone: "",
            promoCode: "",
          })
          setCheckboxChecked(false)
          setErrors({
            name: false,
            email: false,
            phone: false,
            pickup: false,
            pickup_time: false,
            drop: false,
            distance: false,
            checkbox: false,
          })
        } else {
          console.log("Reservation unsuccessful!", response.data)
          alert("Reservation unsuccessful!")
        }
      }).catch((error)=>{
        alert("Reservations are not accepting from web, We don't have any drivers available at the moment.")
      })
    } else {
      console.log("Reservation form is invalid, check for errors")
      alert("Reservation form is invalid, check for errors")
    }
  }

  if (window.innerWidth < 900) {
    return (
      <MobileForm
        estimateCost={estimateCost}
        estimatedCostValue={estimatedCostValue}
        setEstimatedCostValue={setEstimatedCostValue}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        pickupValue={pickupValue}
        setPickupValue={setPickupValue}
        dropValue={dropValue}
        setDropValue={setDropValue}
        distance={distance}
        setDistance={setDistance}
        formValues={formValues}
        setFormValues={setFormValues}
        errors={errors}
        checkboxChecked={checkboxChecked}
        setCheckboxChecked={setCheckboxChecked}
        handleButtonClick={handleButtonClick}
        calculateDistance={calculateDistance}
        handleReservation={handleReservation}
        openPopups={openPopups}
        handleOpenPopup={handleOpenPopup}
        handleClosePopup={handleClosePopup}
      />
    )
  }

  return (
    <Stack
      id="news-bar"
      direction={{ xs: "column", md: "row" }}
      spacing={2}
      sx={{
        height: "100vh",
        backgroundImage: `url('/assets/banner.jpg')`, // Update the path to your image
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",

        paddingBottom: {
          xs: selectedOption === "estimate" ? "0px" : "900px",
          md: 0,
        }, // Adjust '80px' based on the height of your footer
        overflowY: "auto", // Enable vertical scrolling if needed
        width: "100%",
      }}
    >
      {selectedOption === "estimate" ? (
        <>
          <Button
            variant="contained"
            onClick={() => handleButtonClick("reservation")}
            sx={{
              position: "fixed",
              right: "0px", // Positioning the button on the right side
              top: "50%", // Adjust this value to position it vertically in the middle
              transform: "translateY(-50%)", // Centering vertically
              backgroundColor: "#FFCF43", // Button background color
              color: "#000", // Button text color
              padding: "10px 20px", // Adjust padding
              width: "200px", // Set width to allow text wrapping
              whiteSpace: "normal", // Allow text to wrap
              zIndex: 1000, // To make sure it stays on top
              "&:hover": {
                backgroundColor: "#FFD8A3", // Change color on hover
              },
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Add shadow for depth
            }}
          >
            Click Here for Reservation
          </Button>
          <Box
            sx={{
              flex: 0.7,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <motion.div
              key={selectedOption}
              variants={slideVariants}
              initial="enter"
              animate="center"
              exit="exit"
              custom={direction}
              transition={{ type: "spring", stiffness: 300, damping: 30 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  color: "#000",
                  maxHeight: "800px",
                  overflowY: "auto",
                  padding: "18px 30px 52px 30px",
                }}
              >
                <Typography
                  fontSize={29}
                  sx={{ color: "#1e5675", fontWeight: "bold" }}
                >
                  Estimate Your Cost
                </Typography>
                <EstimateForm
                  estimateCost={estimateCost}
                  estimatedCostValue={estimatedCostValue}
                  setEstimatedCostValue={setEstimatedCostValue}
                  pickupValue={pickupValue}
                  setPickupValue={setPickupValue}
                  dropValue={dropValue}
                  setDropValue={setDropValue}
                  distance={distance}
                  setDistance={setDistance}
                  calculateDistance={calculateDistance}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  width="300px"
                />
              </Box>
            </motion.div>
          </Box>
          {/* <Box
            sx={{
              flex: 0.5,
              display: "flex",
              justifyContent: "center",

              alignItems: "center",
              padding: "20px",
              order: { xs: 2, md: 1 }, // Move button below form on mobile
            }}
          ></Box> */}
        </>
      ) : (
        <>
          {/* Reservation Form */}

          <Box
            sx={{
              flex: 0.7,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={() => handleButtonClick("estimate")}
              sx={{
                position: "fixed",
                right: "0px", // Positioning the button on the right side
                top: "50%", // Adjust this value to position it vertically in the middle
                transform: "translateY(-50%)", // Centering vertically
                backgroundColor: "#FFCF43", // Button background color
                color: "#000", // Button text color
                padding: "10px 20px", // Adjust padding
                width: "200px", // Set width to allow text wrapping
                whiteSpace: "normal", // Allow text to wrap
                zIndex: 1000, // To make sure it stays on top
                "&:hover": {
                  backgroundColor: "#FFD8A3", // Change color on hover
                },
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Add shadow for depth
              }}
            >
              Click Here to Estimate Your Cost
            </Button>
            <motion.div
              key={selectedOption}
              variants={slideVariants}
              initial="enter"
              animate="center"
              exit="exit"
              custom={direction}
              transition={{ type: "spring", stiffness: 300, damping: 30 }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  color: "#000",
                  maxHeight: "800px",
                  overflowY: "auto",
                  padding: "18px 30px 52px 30px",
                }}
              >
                <Typography
                  fontSize={29}
                  sx={{ color: "#1e5675", fontWeight: "bold" }}
                >
                  Make Your Reservation
                </Typography>
                <ReservationForm
                  pickupValue={pickupValue}
                  setPickupValue={setPickupValue}
                  dropValue={dropValue}
                  setDropValue={setDropValue}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  errors={errors}
                  checkboxChecked={checkboxChecked}
                  setCheckboxChecked={setCheckboxChecked}
                  handleReservation={handleReservation}
                  openPopups={openPopups}
                  handleOpenPopup={handleOpenPopup}
                  handleClosePopup={handleClosePopup}
                  width="300px"
                />
              </Box>
            </motion.div>
          </Box>
        </>
      )}
      <Box
        sx={{
          flex: 0.5,
          display: "flex",
          justifyContent: "left",

          alignItems: "center",
          padding: "0px",
          order: { xs: 2, md: 1 }, // Move button below form on mobile
        }}
      >
        <MotivateCards />
      </Box>
    </Stack>
  )
}

export default NewsBar
